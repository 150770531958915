/**
 * Source: https://dialog5.vercel.app/
 */

"use client";

import useHasHydrated from "@/hooks/useHasHydrated";
import { Portal } from "@radix-ui/react-portal";
import { Slot } from "@radix-ui/react-slot";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { DialogContext, useDialogContext, useDialogControl } from "./context";
import styles from "./index.module.css";

const cn = (...classes: unknown[]) => classes.filter(Boolean).join(" ");

type AsChild = {
  asChild?: boolean;
};

type RootProps = {
  children: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
};
export const DialogRoot = ({
  children,
  open: externalOpen,
  onOpenChange
}: RootProps) => {
  const hasHydrated = useHasHydrated();
  const [open, setOpen] = useState(false);

  const setOpenState = useCallback(
    (newOpen: boolean) => {
      onOpenChange?.(newOpen);
      setOpen(newOpen);
    },
    [onOpenChange]
  );

  const preferredOpen = typeof externalOpen === "boolean" ? externalOpen : open;

  return (
    <DialogContext.Provider
      value={{ open: preferredOpen, setOpen: setOpenState }}
    >
      {hasHydrated && <Portal>{children}</Portal>}
    </DialogContext.Provider>
  );
};

type ViewportProps = React.ComponentProps<"dialog"> & {
  lock?: boolean;
};

export const DialogViewport = forwardRef<HTMLDialogElement, ViewportProps>(
  ({ children, className, lock = true, ...props }, extRef) => {
    const { open, setOpen } = useDialogContext();

    const { ref } = useDialogControl({ open, setOpen });
    useImperativeHandle(extRef, () => ref.current);

    useEffect(() => {
      const { current: dialog } = ref;
      if (!lock) return;
      if (open) {
        disableBodyScroll(dialog, {
          allowTouchMove: (el) => true
        });
        return () => {
          enableBodyScroll(dialog);
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, lock]);

    /* The only case where any is allowed - @types/react being invalid */
    const ariaProps: any = {
      inert: !open ? "" : undefined,
      "aria-hidden": !open
    };

    return (
      <dialog
        {...props}
        {...ariaProps}
        className={cn(className, styles.dialog)}
        ref={ref}
      >
        {children}
      </dialog>
    );
  }
);

DialogViewport.displayName = "DialogViewport";

type TriggerProps = React.ComponentProps<"button"> & AsChild;
export const DialogTrigger = ({
  children,
  asChild,
  ...props
}: TriggerProps) => {
  const { setOpen } = useDialogContext();
  const Component = asChild ? Slot : "button";
  return (
    <Component {...props} onClick={() => setOpen(true)}>
      {children}
    </Component>
  );
};

export type CloseProps = React.ComponentProps<"button"> & AsChild;
export const DialogClose = ({ children, asChild, ...props }: CloseProps) => {
  const { setOpen } = useDialogContext();
  const Component = asChild ? Slot : "button";
  return (
    <Component {...props} onClick={() => setOpen(false)}>
      {children}
    </Component>
  );
};

export type ContentProps = React.ComponentProps<"div">;
export const DialogContent = forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(({ children, className, ...props }, extRef) => {
  return (
    <div {...props} className={cn(className, styles.content)} ref={extRef}>
      {children}
    </div>
  );
});

DialogContent.displayName = "DialogContent";
